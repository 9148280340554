import './App.css';
import cmLogo from './_assets/_logos/CreativeMatteHero_Logo.png'
import twitterLogo from './_assets/_logos/twitter.png'
import catenaLogo from './_assets/_logos/catena_logo.png'
import emailLogo from './_assets/_logos/email.svg'
import React from 'react';

class Website extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      textState: "Default"
    }
  }

  render() {
    return (
      <div className="main">
        <NavBar />
        <HeroSection />
      </div>
    )
  }
}

function HeroSection() {
  return (
    <div className="hero-section-wrap">
      <div className="hero-section">
        <img className="hero-section-main-image" src={cmLogo} />
        <div className="hero-section-text-wrap">
        <span>Hello. I'm Matt, a multimedia artist, full stack developer, entrepreneur, and investor. This page is being repurposed for a wider audience. You'll soon have easy access to see what I do. Check back soon!</span>
        </div>
      </div>
    </div>
  );
}

function NavButton(props) {
  return (
    <div className="nav-button" id={props.id} onClick={() => window.open(props.link)}>
      <img src={props.img} />
    </div>
  )
}

function NavBar() {
  return (
    <div className="nav-bar">
      <div className="nav-bar-container">
        <NavButton img={catenaLogo} link="https://community.catena.one/u/Matt" />
        <NavButton img={twitterLogo} id="twitter" link="https://twitter.com/creativematte" />

        <NavButton img={emailLogo} link="mailto:contact@creativematte.com" />
      </div>
    </div>
  )
}



export default Website;
